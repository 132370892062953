<template>
    <div>
        

        <div class="content content_top_margin" style="min-height: 175px;">
            <div class="content_inner  ">
                <div class="full_width ">
                    <div class="full_width_inner">

                        <div class="vc_row wpb_row section vc_row-fluid  vc_custom_1636151250857 grid_section"
                            style=" text-align:left;">
                            <div class=" section_inner clearfix">
                                <div class="section_inner_margin clearfix">
                                    <div class="wpb_column vc_column_container vc_col-sm-12">
                                        <div class="vc_column-inner">
                                            <div class="wpb_wrapper">
                                                <div class="vc_row wpb_row section vc_row-fluid vc_inner  single_top_row"
                                                    style=" text-align:left;">
                                                    <div class=" full_section_inner clearfix">
                                                        <div class="wpb_column vc_column_container vc_col-sm-6">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <div class="wpb_wrapper">
                                                                            <h1>Working from home<br>
                                                                                Home stretches</h1>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-6">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <div class="wpb_wrapper">
                                                                            <p>
                                                                                <router-link :to="{ name: 'home-essentials-a45' }">
                                                                                <em>Up
                                                                                        Next: </em> Home Essentials <img
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        src="../../assets/images/airbnb/Airbnb-Arrows-2.png"
                                                                                        alt="" width="64"
                                                                                        height="37">
                                                                                </router-link>
                                                                                        </p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="vc_empty_space" style="height: 52px"><span
                                                        class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span></div>


                                                <div class="wpb_text_column wpb_content_element ">
                                                    <div class="wpb_wrapper">
                                                        <h2>Stretch break anyone?</h2>
                                                        <p>WFH is especially challenging when it comes to your posture
                                                            and comfort. So make it a point to take stretch breaks
                                                            throughout your day to steer clear of muscle tension and to
                                                            destress.</p>

                                                    </div>
                                                </div>
                                                <div class="vc_empty_space" style="height: 32px"><span
                                                        class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span></div>

                                                <div class="vc_row wpb_row section vc_row-fluid vc_inner "
                                                    style=" text-align:left;">
                                                    <div class=" full_section_inner clearfix">
                                                        <div
                                                            class="center wpb_column vc_column_container vc_col-sm-2 vc_hidden-sm vc_hidden-xs">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper"></div>
                                                            </div>
                                                        </div>
                                                        <div class="center wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div class="wpb_text_column wpb_content_element">
                                                                        <div class="wpb_wrapper">
                                                                            <p><a class="image-popup headerVideoLink popup-modal"
                                                                                    href="#" data-bs-toggle="modal"
																						data-bs-target="#exampleModal1"><br>
                                                                                    <img class="img_neak hei_img" src="../../assets/images/airbnb/Work From Home Neck Stretch.png"
                                                                                        alt="Neck"><br>
                                                                                    <span class="button"><img src="../../assets/images/airbnb/playarrow.png" width="15" height="15">
                                                                                        Neck</span><br>
                                                                                </a></p>

                                                                        </div>
                                                                    </div>
                                                                    <div class="vc_empty_space" style="height: 32px">
                                                                        <span class="vc_empty_space_inner">
                                                                            <span class="empty_space_image"></span>
                                                                        </span></div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="center wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <div class="wpb_wrapper">
                                                                            <p><a class="image-popup headerVideoLink popup-modal"
                                                                                     href="#" data-bs-toggle="modal"
																						data-bs-target="#exampleModal2"><br>
                                                                                    <img class="hei_img img_neak" src="../../assets/images/airbnb/shoulder-wfh-stretches.jpg"
                                                                                        alt="Shoulder"><br>
                                                                                    <span class="button"><img src="../../assets/images/airbnb/playarrow.png" width="15" height="15">
                                                                                        Shoulder</span><br>
                                                                                </a></p>

                                                                        </div>
                                                                    </div>
                                                                    <div class="vc_empty_space" style="height: 32px">
                                                                        <span class="vc_empty_space_inner">
                                                                            <span class="empty_space_image"></span>
                                                                        </span></div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="wpb_column vc_column_container vc_col-sm-2 vc_hidden-sm vc_hidden-xs">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="vc_row wpb_row section vc_row-fluid vc_inner "
                                                    style=" text-align:left;">
                                                    <div class=" full_section_inner clearfix">
                                                        <div
                                                            class="wpb_column vc_column_container vc_col-sm-2 vc_hidden-sm vc_hidden-xs">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper"></div>
                                                            </div>
                                                        </div>
                                                        <div class="center wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <div class="wpb_wrapper">
                                                                            <p><a class="image-popup headerVideoLink popup-modal"
                                                                                    href="#" data-bs-toggle="modal"
																						data-bs-target="#exampleModal3"><br>
                                                                                    <img class="hei_img img_neak" src="../../assets/images/airbnb/mid-back-stretch-wfh-stretches.jpg"
                                                                                        alt="Mid Back"><br>
                                                                                    <span class="button"><img src="../../assets/images/airbnb/playarrow.png" width="15" height="15"> Mid
                                                                                        Back</span><br>
                                                                                </a></p>

                                                                        </div>
                                                                    </div>
                                                                    <div class="vc_empty_space" style="height: 32px">
                                                                        <span class="vc_empty_space_inner">
                                                                            <span class="empty_space_image"></span>
                                                                        </span></div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="center wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <div class="wpb_wrapper">
                                                                            <p><a class="image-popup headerVideoLink popup-modal"
                                                                                     href="#" data-bs-toggle="modal"
																						data-bs-target="#exampleModal4"><br>
                                                                                    <img class="img_neak hei_img" src="../../assets/images/airbnb/glutes-wfh-stretches.jpg"
                                                                                        alt="Glutes"><br>
                                                                                    <span class="button"><img src="../../assets/images/airbnb/playarrow.png" width="15" height="15">
                                                                                        Glutes</span><br>
                                                                                </a></p>

                                                                        </div>
                                                                    </div>
                                                                    <div class="vc_empty_space" style="height: 32px">
                                                                        <span class="vc_empty_space_inner">
                                                                            <span class="empty_space_image"></span>
                                                                        </span></div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="wpb_column vc_column_container vc_col-sm-2 vc_hidden-sm vc_hidden-xs">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="vc_row wpb_row section vc_row-fluid  grid_section" style=" text-align:center;">
                            <div class=" section_inner clearfix">
                                <div class="section_inner_margin clearfix">
                                    <div class="wpb_column vc_column_container vc_col-sm-1/5">
                                        <div class="vc_column-inner">
                                            <div class="wpb_wrapper">
                                                <div class="wpb_single_image wpb_content_element vc_align_right">
                                                    <div class="wpb_wrapper">

                                                        <a href="https://www.brightday.com/download-trial"
                                                            target="_blank">
                                                            <div class="vc_single_image-wrapper   vc_box_border_grey">
                                                                <img width="150" height="150"
                                                                    src="../../assets/images/airbnb/ZG0RmEdo-150x150.jpg"
                                                                    class="vc_single_image-img attachment-thumbnail"
                                                                    alt="" loading="lazy" title="ZG0RmEdo"
                                                                    ></div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div class="vc_empty_space" style="height: 32px"><span
                                                        class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span></div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="wpb_column vc_column_container vc_col-sm-3/5">
                                        <div class="vc_column-inner">
                                            <div class="wpb_wrapper">
                                                <div class="wpb_text_column wpb_content_element ">
                                                    <div class="wpb_wrapper">
                                                        <h3 style="text-align: center;">Want to improve your posture?
                                                            We’ve got your back. Literally.</h3>
                                                        <p style="text-align: center;">Check out Airbnb’s newest
                                                            resource to help you track and improve your posture
                                                            throughout your work day.</p>
                                                        <p style="text-align: center;"><em><b>Activation Code:
                                                                    airbnbairbnb</b></em></p>
                                                        <p style="text-align: center;"><a class="button orange"
                                                                href="https://www.brightday.com/download-trial"
                                                                target="_blank" rel="noopener">Download Now</a></p>

                                                    </div>
                                                </div>
                                                <div class="vc_empty_space" style="height: 32px"><span
                                                        class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span></div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="wpb_column vc_column_container vc_col-sm-1/5">
                                        <div class="vc_column-inner">
                                            <div class="wpb_wrapper"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="vc_row wpb_row section vc_row-fluid  grid_section" style=" text-align:left;">
                            <div class=" section_inner clearfix">
                                <div class="section_inner_margin clearfix">
                                    <div class="wpb_column vc_column_container vc_col-sm-12">
                                        <div class="vc_column-inner">
                                            <div class="wpb_wrapper">
                                                <div class="wpb_text_column wpb_content_element ">
                                                    <div class="wpb_wrapper">
                                                        <h2>Continue your work from home journey</h2>

                                                    </div>
                                                </div>
                                                <div class="vc_empty_space" style="height: 32px"><span
                                                        class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span></div>

                                                <div class="vc_row wpb_row section vc_row-fluid vc_inner  continue_block"
                                                    style=" text-align:left;">
                                                    <div class=" full_section_inner clearfix">
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                            <router-link :to="{ name: 'home-essentials-a45' }">
                                                                                <div
                                                                                    class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                    <img width="750" height="500"
                                                                                        src="../../assets/images/airbnb/Airbnb-Work-From-Home-Home-Essentials-.jpg"
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        alt="" loading="lazy"
                                                                                        title="Airbnb-Work-From-Home---Home-Essentials-"
                                                                                        >
                                                                                </div>
                                                                            </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <router-link :to="{ name: 'home-essentials-a45' }">
                                                                        <div class="wpb_wrapper">
                                                                            <h4>The essentials</h4>
                                                                            <p>Create a separate and distinct work space
                                                                                in your home.</p>
                                                                        </div>
                                                                        </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                            <router-link :to="{ name: 'seating-a45' }">
                                                                                <div
                                                                                    class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                    <img width="750" height="530"
                                                                                        src="../../assets/images/airbnb/Airbnb-Work-From-Home-Seating.jpg"
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        alt="" loading="lazy"
                                                                                        title="Airbnb-Work-From-Home-Seating"
                                                                                        >
                                                                                </div>
                                                                            </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <router-link :to="{ name: 'seating-a45' }">
                                                                            <div class="wpb_wrapper">
                                                                                <h4>Seating</h4>
                                                                                <p>It’s safe to say that you sit a lot
                                                                                    during your workday.</p>

                                                                            </div>
                                                                        </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                            <router-link :to="{ name: 'desktop-a45' }">
                                                                                <div
                                                                                    class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                    <img width="750" height="500"
                                                                                        src="../../assets/images/airbnb/Airbnb-Working-From-Home-Desktop.jpg"
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        alt="" loading="lazy"
                                                                                        title="Airbnb-Working-From-Home---Desktop"
                                                                                        >
                                                                                </div>
                                                                            </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                         <router-link :to="{ name: 'desktop-a45' }">
                                                                            <div class="wpb_wrapper">
                                                                                <h4>Desktop</h4>
                                                                                <p>Learn where to place your work items for
                                                                                    optimal comfort and productivity.</p>

                                                                            </div>
                                                                         </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                             <router-link :to="{ name: 'couches-a45' }">
                                                                                <div
                                                                                    class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                    <img width="750" height="500"
                                                                                        src="../../assets/images/airbnb/Airbnb-Work-From-Home-Couches-Landing-page-Footer-and-Page.jpg"
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        alt="" loading="lazy"
                                                                                        title="Airbnb-Work-From-Home-Couches---Landing-page,-Footer-and-Page"
                                                                                        >
                                                                                </div>
                                                                             </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <router-link :to="{ name: 'couches-a45' }">
                                                                            <div class="wpb_wrapper">
                                                                                <h4>Couches</h4>
                                                                                <p>While it’s best to work at a desk or
                                                                                    table top.</p>

                                                                            </div>
                                                                        </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                            <router-link :to="{ name: 'household-ergo-a45' }">
                                                                                <div
                                                                                    class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                    <img width="2560" height="892"
                                                                                        src="../../assets/images/airbnb/REPLACEMENT-Work-From-Home-Household-Ergo2-scaled.jpg"
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        alt="" loading="lazy"
                                                                                        title="REPLACEMENT = Work From Home Household Ergo2"
                                                                                        >
                                                                                </div>
                                                                            </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <router-link :to="{ name: 'household-ergo-a45' }">
                                                                            <div class="wpb_wrapper">
                                                                                <h4>Household ergo</h4>
                                                                                <p>Ergonomics at home doesn’t have to be
                                                                                    fancy.</p>
                                                                            </div>
                                                                        </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                            <router-link :to="{ name: 'home-stretches-a45' }">
                                                                                <div
                                                                                    class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                    <img width="750" height="500"
                                                                                        src="../../assets/images/airbnb/Airbnb-Work-From-Home-Home-Stretches.jpg"
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        alt="" loading="lazy"
                                                                                        title="Airbnb-Work-From-Home---Home-Stretches"
                                                                                        >
                                                                                </div>
                                                                            </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <router-link :to="{ name: 'home-stretches-a45' }">
                                                                            <div class="wpb_wrapper">
                                                                                <h4>Home stretches</h4>
                                                                                <p>These stretches to help you feel good
                                                                                    while you work from home.</p>
                                                                            </div>
                                                                        </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="vc_empty_space" style="height: 32px"><span
                                                        class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span></div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                      
                          <div data-bs-backdrop="false" data-bs-keyboard="false" class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
							<div class="modal-dialog modal-dialog-centered modal-lg">
								<div class="modal-content">
									<div class="modal-body">
										<div class="text-end">
											<a href="#" class="modal_close" data-bs-dismiss="modal" aria-label="Close"
												@click="hideModal">
												X
											</a>
										</div>
										<div style="padding: 56.25% 0 0 0; position: relative">
											<iframe id="closed1"
												src="https://player.vimeo.com/video/327610298?h=88406977fd&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
												allowfullscreen frameborder="0" style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;"></iframe>
										</div>
									</div>
								</div>
							</div>
						</div>
                          <div data-bs-backdrop="false" data-bs-keyboard="false" class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
							<div class="modal-dialog modal-dialog-centered modal-lg">
								<div class="modal-content">
									<div class="modal-body">
										<div class="text-end">
											<a href="#" class="modal_close" data-bs-dismiss="modal" aria-label="Close"
												@click="hideModal2">
												X
											</a>
										</div>
										<div style="padding: 56.25% 0 0 0; position: relative">
											<iframe id="closed2"
												src="https://player.vimeo.com/video/327611727?h=bf40cf57e1&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
												allowfullscreen frameborder="0" style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;"></iframe>
										</div>
									</div>
								</div>
							</div>
						</div>
                          <div data-bs-backdrop="false" data-bs-keyboard="false" class="modal fade" id="exampleModal3" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
							<div class="modal-dialog modal-dialog-centered modal-lg">
								<div class="modal-content">
									<div class="modal-body">
										<div class="text-end">
											<a href="#" class="modal_close" data-bs-dismiss="modal" aria-label="Close"
												@click="hideModal3">
												X
											</a>
										</div>
										<div style="padding: 56.25% 0 0 0; position: relative">
											<iframe id="closed3"
												src="https://player.vimeo.com/video/327609311?h=c9c2d9c312&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
												allowfullscreen frameborder="0" style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;"></iframe>
										</div>
									</div>
								</div>
							</div>
						</div>
                          <div data-bs-backdrop="false" data-bs-keyboard="false" class="modal fade" id="exampleModal4" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
							<div class="modal-dialog modal-dialog-centered modal-lg">
								<div class="modal-content">
									<div class="modal-body">
										<div class="text-end">
											<a href="#" class="modal_close" data-bs-dismiss="modal" aria-label="Close"
												@click="hideModal4">
												X
											</a>
										</div>
										<div style="padding: 56.25% 0 0 0; position: relative">
											<iframe id="closed4"
												src="https://player.vimeo.com/video/327606893?h=2e1622c838&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
												allowfullscreen frameborder="0" style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;"></iframe>
										</div>
									</div>
								</div>
							</div>
						</div>

                    </div>
                </div>
                

            </div>
        </div>
    </div>
</template>
<script>
    //import carousel from "vue-owl-carousel"
    export default {
        name: 'home-stretches-a45',
        mounted(){
			document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
		},
         methods: {
			hideModal() {
			document.getElementById("closed1").src += "";
			},
            hideModal2() {
			document.getElementById("closed2").src += "";
			},
            hideModal3() {
			document.getElementById("closed3").src += "";
			},
            hideModal4() {
			document.getElementById("closed4").src += "";
			},
		}
        
    }
</script>